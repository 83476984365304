var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',[(
            _vm.result &&
            _vm.result.productionTable &&
            _vm.productsList &&
            _vm.productsList.length > 0
          )?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"pl-5 text-center text-h5 font-weight-bold primary--text",attrs:{"colspan":"6"}},[_vm._v(" Produit(s) ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Produit(s) ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Production prévue ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Production effective ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Volume du stock initial ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Volume du stock final ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Valeur du stock final ")])]),_vm._l((_vm.productsList),function(product){return (
                  _vm.result.productionTable.products[product.uuid] != undefined
                )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.products[product.uuid].product.name
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.products[product.uuid]
                      .desiredProduction
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.products[product.uuid]
                      .actualProduction
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.products[product.uuid]
                      .initialStockAmount
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.products[product.uuid]
                      .finalStockAmount
                  )}}),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.productionTable.products[product.uuid]
                          .finalStockValue
                      )
                    )
                  )}})]):_vm._e()})],2)]},proxy:true}],null,false,1763126951)}):_vm._e(),(
            _vm.result &&
            _vm.result.productionTable &&
            _vm.servicesList &&
            _vm.servicesList.length > 0
          )?_c('v-simple-table',{staticClass:"mt-12",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"pl-5 text-center text-h5 font-weight-bold primary--text",attrs:{"colspan":"6"}},[_vm._v(" Service(s) ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Service ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Production max prevue ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Production effective ")])]),_vm._l((_vm.servicesList),function(service){return (
                  _vm.result.productionTable.services[service.uuid] != undefined
                )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.services[service.uuid].service.name
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.services[service.uuid]
                      .desiredProduction
                  )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                    _vm.result.productionTable.services[service.uuid]
                      .actualProduction
                  )}})]):_vm._e()})],2)]},proxy:true}],null,false,1596036328)}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }