<template>
  <span>
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span>
          <v-simple-table v-if="result && result.bilan">
            <template v-slot:default>
              <thead>
                <tr>
                  <th colspan="2" class="primary--text text-center text-h4">
                    Actif
                  </th>
                  <th></th>
                  <th colspan="2" class="primary--text text-center text-h4">
                    Passif
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td class="pl-5 text-left text-h5">
                    Immobilisations corporelles
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.rawCorporealImmo)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">Capital social</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.socialCapital)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5">Amortissements cumulés</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.cumulatedAmortisation)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">
                    Réserves et report à nouveau
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.reserves)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5"></td>
                  <td class="pr-5 text-right text-h5"></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">Résultat</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.result)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Immobilisations corporelles
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.netCorporealImmo)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Situation nette (Capitaux propres)
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.netSituation)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5">
                    Stocks de produits finis
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.stockValue)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">Emprunts</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.loans)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5"></td>
                  <td class="pr-5 text-right text-h5"></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Capitaux permanents
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.permanentCapitals)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5">Créances clients</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.customerDebts)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">Dettes fournisseurs</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.supplierDebts)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Actif circulant
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.circulatingActif)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Passif circulant
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.circulatingPassif)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5">Disponibilités</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.disponibility)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-left text-h5">Découvert</td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.overdraft)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th colspan="5"></th>
                </tr>
                <tr>
                  <td class="pl-5 text-right text-h5 font-weight-bold">
                    Total actif :
                  </td>
                  <td
                    class="pl-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.actif.total)
                      )
                    "
                  ></td>
                  <th></th>
                  <td class="pl-5 text-right text-h5 font-weight-bold">
                    Total passif :
                  </td>
                  <td
                    class="pl-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.passif.total)
                      )
                    "
                  ></td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>

          <v-simple-table
            v-if="result && result.bilan"
            class="mt-12 mb-3"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-if="profile.role != 'User'"
                    class="accent--text text-center text-h6"
                  >
                    Delta
                  </th>
                  <th class="accent--text text-center text-h6">
                    Fonds de Roulement
                  </th>
                  <th class="accent--text text-center text-h6">
                    Besoin en Fonds de Roulement
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th
                    v-if="profile.role != 'User'"
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      dataFormater.formatMoney(
                        Math.abs(
                          parseFloat(result.bilan.actif.total) -
                            parseFloat(result.bilan.passif.total)
                        )
                      )
                    "
                  ></th>
                  <th
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      dataFormater.formatMoney(parseFloat(result.bilan.stat.fr))
                    "
                  ></th>
                  <th
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.bilan.stat.bfr)
                      )
                    "
                  ></th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "Bilan",
  props: ["profile", "user", "session", "result"],
  setup() {
    return {
      dataFormater,
    };
  },
};
</script>
