<template>
  <span
    v-if="
      profile.role != 'User' ||
      (userSession &&
        userSession.displayParameters &&
        userSession.displayParameters.results)
    "
  >
    <v-card>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Résultats</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4" v-show="profile.role != 'User'">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="selectedSession != null"
            v-show="profile.role != 'User'"
          >
            <v-select
              :items="teams"
              item-text="name"
              return-object
              label="Sélectionnez une équipe"
              v-model="selectedTeam"
              @change="OnSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="4" v-if="selectedSession != null">
            <v-select
              :items="periods"
              item-text="name"
              return-object
              label="Sélectionnez une période"
              v-model="selectedPeriod"
              @change="OnSelection"
            ></v-select>
          </v-col>
        </v-row>
        <span class="pa-3">
          <v-row
            class="mb-3 d-flex flex-column align-center justify-center"
            v-if="selectedPeriod != null && profile.role != 'User'"
          >
            <h3>
              Décisions validées : {{ readyDecisions }}/{{ allDecisions }}
              <v-btn color="primary" icon small @click="detailDialog = true">
                <v-icon>mdi-information-slab-box-outline</v-icon>
              </v-btn>
              <v-btn color="primary" icon small @click="reloadStatusData">
                <v-icon>mdi-refresh-circle</v-icon>
              </v-btn>
            </h3>
            <v-dialog
              v-model="detailDialog"
              max-width="500px"
              transition="dialog-transition"
            >
              <v-card class="pa-5">
                <v-card-text>
                  <h1 class="mb-5 text-center">
                    Décisions validées :
                    <v-btn
                      color="primary"
                      icon
                      x-large
                      @click="reloadStatusData"
                    >
                      <v-icon>mdi-refresh-circle</v-icon>
                    </v-btn>
                  </h1>
                  <div
                    class="pa-2 d-flex flex-column align-center justify-center"
                  >
                    <table>
                      <tr
                        v-for="teamStatus in readyStatus"
                        v-if="!teamStatus.status"
                      >
                        <td class="pa-1">
                          <v-icon color="error"
                            >mdi-close-circle-outline</v-icon
                          >
                        </td>
                        <td class="pa-1">
                          <h3>{{ teamStatus.team.name }}</h3>
                        </td>
                        <td class="pa-1">
                          <h3>{{ teamStatus.team.publicName }}</h3>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          readyStatus.filter((item) => !item.status).length >
                            0 &&
                          readyStatus.filter((item) => item.status).length > 0
                        "
                      >
                        <td colspan="3" class="py-3">
                          <v-divider
                            style="border: grey 1px dashed"
                          ></v-divider>
                        </td>
                      </tr>
                      <tr
                        v-for="teamStatus in readyStatus"
                        v-if="teamStatus.status"
                      >
                        <td class="pa-1">
                          <v-icon color="success"
                            >mdi-check-circle-outline</v-icon
                          >
                        </td>
                        <td class="pa-1">
                          <h3>{{ teamStatus.team.name }}</h3>
                        </td>
                        <td class="pa-1">
                          <h3>{{ teamStatus.team.publicName }}</h3>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-progress-linear
              :value="(readyDecisions / allDecisions) * 100"
              color="success"
              rounded
              height="14"
              style="width: 80%"
            ></v-progress-linear>
          </v-row>
          <v-row
            class="mb-3 d-flex flex-row align-center justify-center"
            v-if="selectedPeriod != null"
          >
            <v-col
              cols="12"
              v-if="
                selectedPeriod != null &&
                profile.role != 'User' &&
                profile.role != 'Animator'
              "
              class="d-flex flex-row align-center justify-center flex-wrap"
            >
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                @click="computeResult(selectedPeriod.id)"
                >Calculer ce resultat</v-btn
              >
              <v-btn
                class="ma-2"
                color="error"
                outlined
                @click="deleteResults(selectedSession.id, selectedPeriod.id)"
                >Supprimer ce resultat</v-btn
              >
            </v-col>
            <v-col
              cols="12"
              v-if="selectedPeriod != null && profile.role != 'User'"
              class="d-flex flex-row align-center justify-center flex-wrap"
            >
              <v-switch
                v-if="
                  currentResult &&
                  profile.role != 'User' &&
                  profile.role != 'Animator'
                "
                label="Visibilité des résultats"
                v-model="currentResult.isVisible"
                @change="toggleVisibility"
              ></v-switch>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-row align-center justify-center flex-wrap"
              v-if="selectedPeriod != null"
            >
              <v-btn
                class="ma-2"
                color="success"
                outlined
                @click="exportTable"
                v-if="
                  currentResult &&
                  (currentResult.isVisible || profile.role != 'User')
                "
              >
                <v-icon>mdi-file-excel-outline</v-icon>
                <span>Exporter ces résultats</span>
              </v-btn>
            </v-col>
          </v-row>
          <span
            v-if="
              currentResult &&
              (currentResult.isVisible || profile.role != 'User')
            "
            id="resultWraper"
          >
            <v-tabs
              v-model="currentTab"
              color="primary"
              centered
              slider-color="primary"
            >
              <v-tab
                v-if="
                  profile.role != 'User' || userSession.displayParameters.bilan
                "
              >
                <span>Bilan</span>
              </v-tab>
              <v-tab
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.resultAccount
                "
              >
                <span>Compte de resultat</span>
              </v-tab>
              <v-tab
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.treasuryAccount
                "
              >
                <span>Compte de tresorerie</span>
              </v-tab>
              <v-tab
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.salesTable
                "
              >
                <span>Tableau de vente</span>
              </v-tab>
              <v-tab
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.productionTable
                "
              >
                <span>Tableau de production</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item
                :eager="true"
                class="py-3"
                v-if="
                  profile.role != 'User' || userSession.displayParameters.bilan
                "
              >
                <Bilan
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :result="currentResult"
                  id="bilan"
                />
              </v-tab-item>
              <v-tab-item
                :eager="true"
                class="py-3"
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.resultAccount
                "
              >
                <ResultAccount
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :result="currentResult"
                  id="resultAccount"
                />
              </v-tab-item>
              <v-tab-item
                :eager="true"
                class="py-3"
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.treasuryAccount
                "
              >
                <TreasuryAccount
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :result="currentResult"
                  id="treasuryAccount"
                />
              </v-tab-item>
              <v-tab-item
                :eager="true"
                class="py-3"
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.salesTable
                "
              >
                <SalesTable
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :result="currentResult"
                  id="salesTable"
                />
              </v-tab-item>
              <v-tab-item
                :eager="true"
                class="py-3"
                v-if="
                  profile.role != 'User' ||
                  userSession.displayParameters.productionTable
                "
              >
                <ProductionTable
                  :profile="profile"
                  :user="user"
                  :session="selectedSession"
                  :result="currentResult"
                  id="productionTable"
                />
              </v-tab-item>
            </v-tabs-items>
          </span>
          <span v-else-if="selectedPeriod && selectedTeam && selectedSession">
            <div class="text-center my-10">
              <h2 class="display-1">Résultats indisponible</h2>
              <h3>
                Ces résultats ne sont pas encore calculé ou nécessite des
                données de la période précédente qui ne sont pas encore
                disponibles.
              </h3>
            </div>
          </span>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import Result from "@/classes/Result";
import State from "@/classes/State";
import Decision from "@/classes/Decision";

import Bilan from "@/components/results/Bilan.vue";
import ResultAccount from "@/components/results/ResultAccount.vue";
import TreasuryAccount from "@/components/results/TreasuryAccount.vue";
import SalesTable from "@/components/results/SalesTable.vue";
import ProductionTable from "@/components/results/ProductionTable.vue";

import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import engine from "@/assets/functions/engine/engine.js";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { split } from "lodash";

export default {
  name: "Resultes",
  props: ["user", "profile", "userSession"],
  components: {
    Bilan,
    ResultAccount,
    TreasuryAccount,
    SalesTable,
    ProductionTable,
  },
  setup() {
    return {
      dataFormater,
      dataExtractor,
      engine,
    };
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      teams: [],
      periods: [],

      detailDialog: false,
      readyDecisions: 0,
      allDecisions: 0,
      readyStatus: [],

      selectedSession: null,
      selectedTeam: null,
      selectedPeriod: null,

      currentTab: null,

      currentResult: null,
    };
  },
  async created() {
    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      this.selectedTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    } else {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
        })
      );
    }
  },
  methods: {
    async OnSessionSelected() {
      let sessionUniverses = await Univers.getBySession(
        this.selectedSession.id
      );
      let tmp_teams = [];
      this.teams = [];

      this.selectedPeriod = null;
      this.selectedTeam = null;

      this.currentResult = null;

      sessionUniverses.forEach((univers) => {
        this.unsub.push(
          Team.listenByUnivers(univers.id, (teams) => {
            teams.forEach((team) => {
              tmp_teams.push(team);
            });
            tmp_teams.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.teams = tmp_teams;
          })
        );
      });
      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods;
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    },
    async OnSelection() {
      if (this.selectedTeam != null && this.selectedPeriod != null) {
        await this.loadResult();
      }
      if (this.selectedPeriod != null) {
        let decisions = await Decision.getByPeriod(this.selectedPeriod.id);
        this.allDecisions = decisions.length;
        this.readyDecisions = decisions.filter(
          (decision) => decision.status.isReady
        ).length;
        this.readyStatus = [];
        for (let decision of decisions) {
          if (this.teams.find((team) => team.id == decision.team)) {
            let team = this.teams.find((team) => team.id == decision.team);

            this.readyStatus.push({
              status: decision.status.isReady,
              team: team,
            });
          }
        }
        this.readyStatus.sort((a, b) => {
          if (a.status && !b.status) {
            return 1;
          } else if (!a.status && b.status) {
            return -1;
          } else {
            return a.team.name.localeCompare(b.team.name);
          }
        });
      }
    },
    async reloadStatusData() {
      if (this.selectedPeriod != null) {
        let decisions = await Decision.getByPeriod(this.selectedPeriod.id);
        this.allDecisions = decisions.length;
        this.readyDecisions = decisions.filter(
          (decision) => decision.status.isReady
        ).length;
        this.readyStatus = [];
        for (let decision of decisions) {
          if (this.teams.find((team) => team.id == decision.team)) {
            let team = this.teams.find((team) => team.id == decision.team);

            this.readyStatus.push({
              status: decision.status.isReady,
              team: team,
            });
          }
        }
        this.readyStatus.sort((a, b) => {
          if (a.status && !b.status) {
            return 1;
          } else if (!a.status && b.status) {
            return -1;
          } else {
            return a.team.name.localeCompare(b.team.name);
          }
        });
      }
    },
    async loadResult() {
      this.unsub.push(
        Result.listenByPeriodAndTeam(
          this.selectedPeriod.id,
          this.selectedTeam.id,
          (results) => {
            this.currentResult = results[0];
          }
        )
      );
    },
    exportTable() {
      dataExtractor.exportResult("resultWraper");
    },
    async computeResult(periodId) {
      try {
        await this.engine.compute(periodId);
      } catch (e) {
        console.log(e.message);
        if (e.message.includes("Challenge correction not found for team")) {
          Swal.fire({
            title:
              "Une ou plusieurs correction(s) de challenges sont manquantes !",
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          console.log("ERROR MESSAGE", e.message);
          let error = JSON.parse(e.message);

          if (error && error.type && error.type == "DecisionDoNotExist") {
            Swal.fire({
              title: "Certaines décisions sont manquantes !",
              html: error.teams.join("<br>"),
              icon: "error",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              title: "Une erreur est survenue !",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        }
      }
      this.selectedTeam = this.teams[0];
    },
    async deleteResults(sessionId, periodId) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer ces résultats?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let periods = await Period.getBySession(sessionId);
          periods.sort((a, b) => a.end - b.end);
          let periodIndex = periods.findIndex(
            (period) => period.id == periodId
          );
          periods.forEach(async (period, index) => {
            if (index >= periodIndex) {
              let oldResults = await Result.getByPeriod(period.id);
              for (let result of oldResults) {
                await result.delete();
              }
              let oldStates = await State.getByPeriod(period.id);
              for (let state of oldStates) {
                await state.delete();
              }
            }
            if (index > periodIndex) {
              let oldDecisions = await Decision.getByPeriod(period.id);
              for (let decision of oldDecisions) {
                await decision.delete();
              }
            }
          });
        }
      });
    },
    async toggleVisibility() {
      if (this.currentResult) {
        let visibility = JSON.parse(
          JSON.stringify(this.currentResult.isVisible)
        );
        let period = JSON.parse(JSON.stringify(this.currentResult.period));

        for (let team of this.teams) {
          let result = await Result.getByPeriodAndTeam(period, team.id);
          if (result.length > 0) {
            result = result[0];
            result.isVisible = visibility;
            await result.save();
          }
        }
      }
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
