var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","id":"treasuryAccount1"}},[(_vm.result && _vm.result.bilan)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"primary--text text-center text-h4 success--text",attrs:{"colspan":"2"}},[_vm._v(" Entrées/Débits ")])])]),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Augmentation du capital ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.capitalIncrease
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Encaissements des emprunts ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.loanReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Subventions d'exploitations "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                            'Valeur des subventions obtenues cette période pour vos différents challenges'
                          )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.subventions
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Cessions de machines ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.machineSales
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Placements")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.treasuryPlacements
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Encaissement créances clients ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs
                              .customerDebtsReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Ventes encaissées "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                            'Part du Chiffre d\'affaires encaissé correspondant aux ventes de produits finis toutes zones confondues  (provenant de la production comme du marketplace)'
                          )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.salesReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Ventes de marchandises "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                            'Valeur des ventes de marchandises effectuées sur le marketplace'
                          )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs
                              .marketplaceSalesReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Pénalités contractuelles perçues ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs
                              .marketplacePenalityReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Encaissement d'escomptes ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.inputs.discountReceived
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Autres produits d'exploitation ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.otherExploitationProductsTotal)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Autres produits financiers ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.otherFinancialProductsTotal)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Autres produits exceptionnels ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.otherExceptionalProductsTotal)
                        )
                      )}})]),_c('tr',[_c('th',{attrs:{"colspan":"2"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold"},[_vm._v(" Total entrées/Débits ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.inputs.total)
                        )
                      )}})])])]},proxy:true}],null,false,2019049509)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","id":"treasuryAccount2"}},[(_vm.result && _vm.result.bilan)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"primary--text text-center text-h4 error--text",attrs:{"colspan":"2"}},[_vm._v(" Sorties/Crédits ")])])]),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Remboursements emprunts ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.outputs.loanPaid)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Acquisitions de machines ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.machinePurchased
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Frais de structure")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.structuralCosts
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Frais de structures spécifiques machines ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs
                              .machineStructuralCosts
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Frais de distribution et de stockage ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs
                              .distributionStorageCosts
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Budget de communication ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.communicationBudget
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Budget de marketing ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.marketingBudget
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Budget de maintenance ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.maintenanceBudget
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Coûts Recherche & Développement ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.outputs.rndCosts)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Achats d'etudes")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.outputs.studies)
                        )
                      )}})]),(
                      parseFloat(_vm.result.treasuryAccount.outputs.workerCosts) >
                      0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Coût des ouvriers")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.workerCosts
                          )
                        )
                      )}})]):_vm._e(),(
                      parseFloat(
                        _vm.result.treasuryAccount.outputs.employeeCosts
                      ) > 0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Coût des employés")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.employeeCosts
                          )
                        )
                      )}})]):_vm._e(),(
                      parseFloat(
                        _vm.result.treasuryAccount.outputs.productManagerCosts
                      ) > 0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Coût des chefs")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.productManagerCosts
                          )
                        )
                      )}})]):_vm._e(),(
                      parseFloat(
                        _vm.result.treasuryAccount.outputs.serviceManagerCosts
                      ) > 0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Coût des managers")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.serviceManagerCosts
                          )
                        )
                      )}})]):_vm._e(),(
                      parseFloat(
                        _vm.result.treasuryAccount.outputs.salesmanCosts
                      ) > 0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Coût des commerciaux ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.salesmanCosts
                          )
                        )
                      )}})]):_vm._e(),(
                      parseFloat(_vm.result.treasuryAccount.outputs.commissions) +
                        parseFloat(
                          _vm.result.treasuryAccount.outputs.salesmanCosts
                        ) >
                      0
                    )?_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Commissions")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.commissions
                          )
                        )
                      )}})]):_vm._e(),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Coût des formations ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.trainingCosts
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Budget social")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.socialBudget
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Frais d'embauches et de licenciements ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.hiringFiringCosts
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Règlements dettes fournisseurs ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.supplierDebtsPaid
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Achats fournisseurs ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.rawMaterialsPaid
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Achats de marchandises "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                            'Valeur des achats de produits fini provenants du marketplace'
                          )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs
                              .marketplaceSalesPaid
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Pénalités contractuelles versées ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs
                              .marketplacePenalityPaid
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Charges financières ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(
                            _vm.result.treasuryAccount.outputs.financialCharges
                          )
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Impôts payés")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.outputs.tax)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Autres charges d'exploitation ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.otherExploitationChargesTotal)
                        )
                      )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Autres charges exceptionnelles ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.otherExceptionalChargesTotal)
                        )
                      )}})]),_c('tr',[_c('th',{attrs:{"colspan":"2"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold"},[_vm._v(" Total sorties/Crédits ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                        _vm.dataFormater.formatMoney(
                          parseFloat(_vm.result.treasuryAccount.outputs.total)
                        )
                      )}})])])]},proxy:true}],null,false,1254554293)}):_vm._e()],1)],1),(_vm.result && _vm.result.bilan)?_c('v-simple-table',{staticClass:"mt-12 mb-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v(" Solde antérieur ")]),_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v(" Variations de solde ")]),_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v("Solde final")])])]),_c('tfoot',[_c('tr',[_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.treasuryAccount.previousTreasury)
                    )
                  )}}),_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    (parseFloat(_vm.result.treasuryAccount.treasuryVariation) > 0
                      ? '+ '
                      : '') +
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.treasuryAccount.treasuryVariation)
                    )
                  )}}),_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.treasuryAccount.treasury)
                    )
                  )}})])])]},proxy:true}],null,false,451928264)}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }