<template>
  <span>
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span>
          <v-simple-table
            v-if="
              result &&
              result.salesTable &&
              zonesList &&
              productsList &&
              zonesList.length > 0 &&
              productsList.length > 0
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="pl-5 primary--text text-center text-h5 font-weight-bold"
                    colspan="7"
                  >
                    Produit(s)
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">Zone</td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Produit(s)
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Prix de vente
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Proposés à la vente
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Demande clients
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Quantité de produits que vos clients souhaitaient acheter (n\'inclut pas les clients d\'autres entreprises qui ont finalement acheté chez vous faute au manque de disponibilité chez un concurent).'
                        "
                      ></span>
                    </v-tooltip>
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Ventes
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Valeur des ventes
                  </td>
                </tr>
                <template
                  v-for="zone of zonesList"
                  v-if="result.salesTable[zone.uuid] != undefined"
                >
                  <tr
                    v-for="product of productsList"
                    v-if="
                      result.salesTable[zone.uuid].products != undefined &&
                      result.salesTable[zone.uuid].products[product.uuid] !=
                        undefined
                    "
                  >
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="zone.name"
                      :rowspan="productsList.length"
                      v-if="product.uuid == productsList[0].uuid"
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="product.name"
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            result.salesTable[zone.uuid].products[product.uuid]
                              .price
                          )
                        )
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].products[product.uuid]
                          .amount
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].products[product.uuid]
                          .amount > 0
                          ? result.salesTable[zone.uuid].products[product.uuid]
                              .demand
                          : 'N/A'
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].products[product.uuid]
                          .sales
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            result.salesTable[zone.uuid].products[product.uuid]
                              .turnover
                          )
                        )
                      "
                    ></td>
                  </tr>
                </template>
                <tr>
                  <td colspan="7"></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-right text-h5 font-weight-bold"
                    colspan="6"
                  >
                    Valeur totale des ventes de produits :
                  </td>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    v-text="dataFormater.formatMoney(totalProductTurnover)"
                  ></td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="
              result &&
              result.salesTable &&
              zonesList &&
              servicesList &&
              zonesList.length > 0 &&
              servicesList.length > 0
            "
            class="mt-12"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="pl-5 primary--text text-center text-h5 font-weight-bold"
                    colspan="7"
                  >
                    Service(s)
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">Zone</td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Service
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Prix de vente
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Proposes à la vente
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Demande clients
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Ventes
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Valeur des ventes
                  </td>
                </tr>
                <template
                  v-for="zone of zonesList"
                  v-if="result.salesTable[zone.uuid] != undefined"
                >
                  <tr
                    v-for="service of servicesList"
                    v-if="
                      result.salesTable[zone.uuid].services != undefined &&
                      result.salesTable[zone.uuid].services[service.uuid] !=
                        undefined
                    "
                  >
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="zone.name"
                      :rowspan="servicesList.length"
                      v-if="service.uuid == servicesList[0].uuid"
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="service.name"
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            result.salesTable[zone.uuid].services[service.uuid]
                              .price
                          )
                        )
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].services[service.uuid]
                          .amount
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].services[service.uuid]
                          .amount > 0
                          ? result.salesTable[zone.uuid].services[service.uuid]
                              .demand
                          : 'N/A'
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        result.salesTable[zone.uuid].services[service.uuid]
                          .sales
                      "
                    ></td>
                    <td
                      class="pl-5 text-left text-h5"
                      v-text="
                        dataFormater.formatMoney(
                          parseFloat(
                            result.salesTable[zone.uuid].services[service.uuid]
                              .turnover
                          )
                        )
                      "
                    ></td>
                  </tr>
                </template>
                <tr>
                  <td colspan="7"></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-right text-h5 font-weight-bold"
                    colspan="6"
                  >
                    Valeur totale des ventes de services :
                  </td>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    v-text="dataFormater.formatMoney(totalServiceTurnover)"
                  ></td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "SalesTable",
  props: ["profile", "user", "session", "result"],
  setup() {
    return {
      dataFormater,
    };
  },
  computed: {
    zonesList() {
      let zones = Object.values(this.session.zonesConfig);
      zones.sort((a, b) => (a.name > b.name ? 1 : -1));
      return zones;
    },
    productsList() {
      let products = Object.values(this.session.productsConfig);
      products.sort((a, b) => (a.name > b.name ? 1 : -1));
      return products;
    },
    servicesList() {
      let services = Object.values(this.session.servicesConfig);
      services.sort((a, b) => (a.name > b.name ? 1 : -1));
      return services;
    },
    totalProductTurnover() {
      let productTurnover = 0;

      // console.log("TEEEEESTT NAN", this.result.salesTable);
      for (let zone of this.zonesList) {
        for (let product of this.productsList) {
          if (
            this.result.salesTable[zone.uuid] != undefined &&
            this.result.salesTable[zone.uuid].products != undefined &&
            this.result.salesTable[zone.uuid].products[product.uuid] !=
              undefined
          ) {
            productTurnover += parseFloat(
              this.result.salesTable[zone.uuid].products[product.uuid].turnover
            );
          }
        }
      }
      return productTurnover;
    },
    totalServiceTurnover() {
      let serviceTurnover = 0;
      for (let zone of this.zonesList) {
        for (let service of this.servicesList) {
          if (
            this.result.salesTable[zone.uuid] != undefined &&
            this.result.salesTable[zone.uuid].services != undefined &&
            this.result.salesTable[zone.uuid].services[service.uuid] !=
              undefined
          ) {
            serviceTurnover += parseFloat(
              this.result.salesTable[zone.uuid].services[service.uuid].turnover
            );
          }
        }
      }
      return serviceTurnover;
    },
  },
};
</script>
