var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',[(
            _vm.result &&
            _vm.result.salesTable &&
            _vm.zonesList &&
            _vm.productsList &&
            _vm.zonesList.length > 0 &&
            _vm.productsList.length > 0
          )?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"pl-5 primary--text text-center text-h5 font-weight-bold",attrs:{"colspan":"7"}},[_vm._v(" Produit(s) ")])])]),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v("Zone")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Produit(s) ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Prix de vente ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Proposés à la vente ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Demande clients "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                        'Quantité de produits que vos clients souhaitaient acheter (n\'inclut pas les clients d\'autres entreprises qui ont finalement acheté chez vous faute au manque de disponibilité chez un concurent).'
                      )}})])],1),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Ventes ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Valeur des ventes ")])]),_vm._l((_vm.zonesList),function(zone){return (_vm.result.salesTable[zone.uuid] != undefined)?_vm._l((_vm.productsList),function(product){return (
                    _vm.result.salesTable[zone.uuid].products != undefined &&
                    _vm.result.salesTable[zone.uuid].products[product.uuid] !=
                      undefined
                  )?_c('tr',[(product.uuid == _vm.productsList[0].uuid)?_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"rowspan":_vm.productsList.length},domProps:{"textContent":_vm._s(zone.name)}}):_vm._e(),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(product.name)}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.dataFormater.formatMoney(
                        parseFloat(
                          _vm.result.salesTable[zone.uuid].products[product.uuid]
                            .price
                        )
                      )
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].products[product.uuid]
                        .amount
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].products[product.uuid]
                        .amount > 0
                        ? _vm.result.salesTable[zone.uuid].products[product.uuid]
                            .demand
                        : 'N/A'
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].products[product.uuid]
                        .sales
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.dataFormater.formatMoney(
                        parseFloat(
                          _vm.result.salesTable[zone.uuid].products[product.uuid]
                            .turnover
                        )
                      )
                    )}})]):_vm._e()}):_vm._e()}),_c('tr',[_c('td',{attrs:{"colspan":"7"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold",attrs:{"colspan":"6"}},[_vm._v(" Valeur totale des ventes de produits : ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",domProps:{"textContent":_vm._s(_vm.dataFormater.formatMoney(_vm.totalProductTurnover))}})])],2)]},proxy:true}],null,false,3864291656)}):_vm._e(),(
            _vm.result &&
            _vm.result.salesTable &&
            _vm.zonesList &&
            _vm.servicesList &&
            _vm.zonesList.length > 0 &&
            _vm.servicesList.length > 0
          )?_c('v-simple-table',{staticClass:"mt-12",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"pl-5 primary--text text-center text-h5 font-weight-bold",attrs:{"colspan":"7"}},[_vm._v(" Service(s) ")])])]),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v("Zone")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Service ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Prix de vente ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Proposes à la vente ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Demande clients ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Ventes ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Valeur des ventes ")])]),_vm._l((_vm.zonesList),function(zone){return (_vm.result.salesTable[zone.uuid] != undefined)?_vm._l((_vm.servicesList),function(service){return (
                    _vm.result.salesTable[zone.uuid].services != undefined &&
                    _vm.result.salesTable[zone.uuid].services[service.uuid] !=
                      undefined
                  )?_c('tr',[(service.uuid == _vm.servicesList[0].uuid)?_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"rowspan":_vm.servicesList.length},domProps:{"textContent":_vm._s(zone.name)}}):_vm._e(),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(service.name)}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.dataFormater.formatMoney(
                        parseFloat(
                          _vm.result.salesTable[zone.uuid].services[service.uuid]
                            .price
                        )
                      )
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].services[service.uuid]
                        .amount
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].services[service.uuid]
                        .amount > 0
                        ? _vm.result.salesTable[zone.uuid].services[service.uuid]
                            .demand
                        : 'N/A'
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.result.salesTable[zone.uuid].services[service.uuid]
                        .sales
                    )}}),_c('td',{staticClass:"pl-5 text-left text-h5",domProps:{"textContent":_vm._s(
                      _vm.dataFormater.formatMoney(
                        parseFloat(
                          _vm.result.salesTable[zone.uuid].services[service.uuid]
                            .turnover
                        )
                      )
                    )}})]):_vm._e()}):_vm._e()}),_c('tr',[_c('td',{attrs:{"colspan":"7"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold",attrs:{"colspan":"6"}},[_vm._v(" Valeur totale des ventes de services : ")]),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",domProps:{"textContent":_vm._s(_vm.dataFormater.formatMoney(_vm.totalServiceTurnover))}})])],2)]},proxy:true}],null,false,3959417107)}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }