<template>
  <span>
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span>
          <v-row>
            <v-col cols="12" md="6" id="treasuryAccount1">
              <v-simple-table v-if="result && result.bilan">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        colspan="2"
                        class="primary--text text-center text-h4 success--text"
                      >
                        Entrées/Débits
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Augmentation du capital
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.capitalIncrease
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Encaissements des emprunts
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.loanReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Subventions d'exploitations
                        <v-tooltip bottom max-width="600" color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="accent"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>mdi-help</v-icon>
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              'Valeur des subventions obtenues cette période pour vos différents challenges'
                            "
                          ></span>
                        </v-tooltip>
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.subventions
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Cessions de machines
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.machineSales
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">Placements</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.treasuryPlacements
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Encaissement créances clients
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs
                                .customerDebtsReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Ventes encaissées
                        <v-tooltip bottom max-width="600" color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="accent"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>mdi-help</v-icon>
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              'Part du Chiffre d\'affaires encaissé correspondant aux ventes de produits finis toutes zones confondues  (provenant de la production comme du marketplace)'
                            "
                          ></span>
                        </v-tooltip>
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.salesReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Ventes de marchandises
                        <v-tooltip bottom max-width="600" color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="accent"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>mdi-help</v-icon>
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              'Valeur des ventes de marchandises effectuées sur le marketplace'
                            "
                          ></span>
                        </v-tooltip>
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs
                                .marketplaceSalesReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Pénalités contractuelles perçues
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs
                                .marketplacePenalityReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Encaissement d'escomptes
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.inputs.discountReceived
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Autres produits d'exploitation
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(otherExploitationProductsTotal)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Autres produits financiers
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(otherFinancialProductsTotal)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Autres produits exceptionnels
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(otherExceptionalProductsTotal)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <th colspan="2"></th>
                    </tr>
                    <tr>
                      <td class="pl-5 text-right text-h5 font-weight-bold">
                        Total entrées/Débits
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.inputs.total)
                          )
                        "
                      ></td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6" id="treasuryAccount2">
              <v-simple-table v-if="result && result.bilan">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        colspan="2"
                        class="primary--text text-center text-h4 error--text"
                      >
                        Sorties/Crédits
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <!-- <tr>
                                            <td class="pl-5 text-left text-h5">Dividendes distribuées</td>
                                            <td class="pr-5 text-right text-h5" v-text="dataFormater.formatMoney(parseFloat(result.treasuryAccount.outputs.dividendsPaid))"></td>
                                        </tr> -->
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Remboursements emprunts
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.outputs.loanPaid)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Acquisitions de machines
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.machinePurchased
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">Frais de structure</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.structuralCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Frais de structures spécifiques machines
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs
                                .machineStructuralCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Frais de distribution et de stockage
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs
                                .distributionStorageCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Budget de communication
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.communicationBudget
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Budget de marketing
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.marketingBudget
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Budget de maintenance
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.maintenanceBudget
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Coûts Recherche & Développement
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.outputs.rndCosts)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">Achats d'etudes</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.outputs.studies)
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(result.treasuryAccount.outputs.workerCosts) >
                        0
                      "
                    >
                      <td class="pl-5 text-left text-h5">Coût des ouvriers</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.workerCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(
                          result.treasuryAccount.outputs.employeeCosts
                        ) > 0
                      "
                    >
                      <td class="pl-5 text-left text-h5">Coût des employés</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.employeeCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(
                          result.treasuryAccount.outputs.productManagerCosts
                        ) > 0
                      "
                    >
                      <td class="pl-5 text-left text-h5">Coût des chefs</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.productManagerCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(
                          result.treasuryAccount.outputs.serviceManagerCosts
                        ) > 0
                      "
                    >
                      <td class="pl-5 text-left text-h5">Coût des managers</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.serviceManagerCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(
                          result.treasuryAccount.outputs.salesmanCosts
                        ) > 0
                      "
                    >
                      <td class="pl-5 text-left text-h5">
                        Coût des commerciaux
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.salesmanCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr
                      v-if="
                        parseFloat(result.treasuryAccount.outputs.commissions) +
                          parseFloat(
                            result.treasuryAccount.outputs.salesmanCosts
                          ) >
                        0
                      "
                    >
                      <td class="pl-5 text-left text-h5">Commissions</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.commissions
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Coût des formations
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.trainingCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">Budget social</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.socialBudget
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Frais d'embauches et de licenciements
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.hiringFiringCosts
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Règlements dettes fournisseurs
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.supplierDebtsPaid
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Achats fournisseurs
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.rawMaterialsPaid
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Achats de marchandises
                        <v-tooltip bottom max-width="600" color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="accent"
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>mdi-help</v-icon>
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              'Valeur des achats de produits fini provenants du marketplace'
                            "
                          ></span>
                        </v-tooltip>
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs
                                .marketplaceSalesPaid
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Pénalités contractuelles versées
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs
                                .marketplacePenalityPaid
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Charges financières
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(
                              result.treasuryAccount.outputs.financialCharges
                            )
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">Impôts payés</td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.outputs.tax)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Autres charges d'exploitation
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(otherExploitationChargesTotal)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <td class="pl-5 text-left text-h5">
                        Autres charges exceptionnelles
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(otherExceptionalChargesTotal)
                          )
                        "
                      ></td>
                    </tr>
                    <tr>
                      <th colspan="2"></th>
                    </tr>
                    <tr>
                      <td class="pl-5 text-right text-h5 font-weight-bold">
                        Total sorties/Crédits
                      </td>
                      <td
                        class="pr-5 text-right text-h5"
                        v-text="
                          dataFormater.formatMoney(
                            parseFloat(result.treasuryAccount.outputs.total)
                          )
                        "
                      ></td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-simple-table
            v-if="result && result.bilan"
            class="mt-12 mb-3"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="accent--text text-center text-h6">
                    Solde antérieur
                  </th>
                  <th class="accent--text text-center text-h6">
                    Variations de solde
                  </th>
                  <th class="accent--text text-center text-h6">Solde final</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.treasuryAccount.previousTreasury)
                      )
                    "
                  ></th>
                  <th
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      (parseFloat(result.treasuryAccount.treasuryVariation) > 0
                        ? '+ '
                        : '') +
                      dataFormater.formatMoney(
                        parseFloat(result.treasuryAccount.treasuryVariation)
                      )
                    "
                  ></th>
                  <th
                    class="pl-5 text-center text-subtitle font-weight-bold text-h6"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.treasuryAccount.treasury)
                      )
                    "
                  ></th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "TreasuryAccount",
  props: ["profile", "user", "session", "result"],
  setup() {
    return {
      dataFormater,
    };
  },
  computed: {
    otherExploitationProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.inputs &&
        this.result.treasuryAccount.inputs.otherExploitationProducts
      ) {
        this.result.treasuryAccount.inputs.otherExploitationProducts.forEach(
          (exploitationProduct) => {
            total += parseFloat(exploitationProduct.value);
          }
        );
      }
      return total;
    },
    otherExploitationChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.outputs &&
        this.result.treasuryAccount.outputs.otherExploitationCharges
      ) {
        this.result.treasuryAccount.outputs.otherExploitationCharges.forEach(
          (exploitationCharge) => {
            total += parseFloat(exploitationCharge.value);
          }
        );
      }
      return total;
    },
    otherFinancialProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.inputs &&
        this.result.treasuryAccount.inputs.otherFinancialProducts
      ) {
        this.result.treasuryAccount.inputs.otherFinancialProducts.forEach(
          (financialProduct) => {
            total += parseFloat(financialProduct.value);
          }
        );
      }
      return total;
    },
    otherFinancialChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.outputs &&
        this.result.treasuryAccount.outputs.otherFinancialCharges
      ) {
        this.result.treasuryAccount.outputs.otherFinancialCharges.forEach(
          (financialCharge) => {
            total += parseFloat(financialCharge.value);
          }
        );
      }
      return total;
    },
    otherExceptionalProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.inputs &&
        this.result.treasuryAccount.inputs.otherExceptionalProducts
      ) {
        this.result.treasuryAccount.inputs.otherExceptionalProducts.forEach(
          (exceptionalProduct) => {
            total += parseFloat(exceptionalProduct.value);
          }
        );
      }
      return total;
    },
    otherExceptionalChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.treasuryAccount &&
        this.result.treasuryAccount.outputs &&
        this.result.treasuryAccount.outputs.otherExceptionalCharges
      ) {
        this.result.treasuryAccount.outputs.otherExceptionalCharges.forEach(
          (exceptionalCharge) => {
            total += parseFloat(exceptionalCharge.value);
          }
        );
      }
      return total;
    },
  },
};
</script>
