var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',[(_vm.result && _vm.result.bilan)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"primary--text text-center text-h4",attrs:{"colspan":"2"}},[_vm._v(" Actif ")]),_c('th'),_c('th',{staticClass:"primary--text text-center text-h4",attrs:{"colspan":"2"}},[_vm._v(" Passif ")])])]),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Immobilisations corporelles ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.rawCorporealImmo)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Capital social")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.socialCapital)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Amortissements cumulés")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.cumulatedAmortisation)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Réserves et report à nouveau ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.reserves)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"}),_c('td',{staticClass:"pr-5 text-right text-h5"}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Résultat")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.result)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Immobilisations corporelles ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.netCorporealImmo)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Situation nette (Capitaux propres) ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.netSituation)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v(" Stocks de produits finis ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.stockValue)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Emprunts")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.loans)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"}),_c('td',{staticClass:"pr-5 text-right text-h5"}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Capitaux permanents ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.permanentCapitals)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Créances clients")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.customerDebts)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Dettes fournisseurs")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.supplierDebts)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Actif circulant ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.circulatingActif)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold"},[_vm._v(" Passif circulant ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.circulatingPassif)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Disponibilités")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.disponibility)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5"},[_vm._v("Découvert")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.overdraft)
                    )
                  )}})]),_c('tr',[_c('th',{attrs:{"colspan":"5"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold"},[_vm._v(" Total actif : ")]),_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.actif.total)
                    )
                  )}}),_c('th'),_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold"},[_vm._v(" Total passif : ")]),_c('td',{staticClass:"pl-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.passif.total)
                    )
                  )}})])])]},proxy:true}],null,false,3589595457)}):_vm._e(),(_vm.result && _vm.result.bilan)?_c('v-simple-table',{staticClass:"mt-12 mb-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.profile.role != 'User')?_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v(" Delta ")]):_vm._e(),_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v(" Fonds de Roulement ")]),_c('th',{staticClass:"accent--text text-center text-h6"},[_vm._v(" Besoin en Fonds de Roulement ")])])]),_c('tfoot',[_c('tr',[(_vm.profile.role != 'User')?_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      Math.abs(
                        parseFloat(_vm.result.bilan.actif.total) -
                          parseFloat(_vm.result.bilan.passif.total)
                      )
                    )
                  )}}):_vm._e(),_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(parseFloat(_vm.result.bilan.stat.fr))
                  )}}),_c('th',{staticClass:"pl-5 text-center text-subtitle font-weight-bold text-h6",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.bilan.stat.bfr)
                    )
                  )}})])])]},proxy:true}],null,false,959340942)}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }