<template>
  <span>
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span>
          <v-simple-table v-if="result && result.resultAccount">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat d'exploitation
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.exploitation.result)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Produits d'exploitation
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products.total
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Chiffre d'Affaires
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products.turnover
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.products
                        .productsSalesValue
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Ventes de produits finis
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Chiffre d\'affaires correspondant aux ventes de produits finis toutes zones confondues  (provenant de la production comme du marketplace)'
                        "
                      ></span>
                    </v-tooltip>
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products
                            .productsSalesValue
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.products
                        .servicesSalesValue
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Ventes de services
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products
                            .servicesSalesValue
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.products
                        .marketplaceSalesReceived
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Ventes de marchandises
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Chiffre d\'affaires correspondant aux ventes de marchandises effectuées sur le marketplace'
                        "
                      ></span>
                    </v-tooltip>
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products
                            .marketplaceSalesReceived
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Variation stocks de produits finis
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products
                            .stockVariation
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Subventions d'exploitation
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Valeur des subventions obtenues cette période pour vos différents challenges '
                        "
                      ></span>
                    </v-tooltip>
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.products.subventions
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres produits d'exploitations
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherExplotationProductsTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="exploitationProduct in result.resultAccount
                    .exploitation.products.otherProducts"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="exploitationProduct.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(exploitationProduct.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Charges d'exploitation
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.total
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Achats fournisseurs
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.rawMaterials
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Matières premières et consommables
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .materialCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Budget RSE et qualité
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .qualityBudget
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges
                        .marketplaceSalesPaid
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Achats de marchandises
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          'Valeur des achats de produits finis provenant du marketplace'
                        "
                      ></span>
                    </v-tooltip>
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .marketplaceSalesPaid
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Coûts de services externes
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .externalCharges
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Frais de structure
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .structuralCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Frais de structures spécifiques machines
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .machineStructuralCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Frais de stockage
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.storageCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Frais de distribution
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .distributionCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Budget de communication
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .communicationBudget
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Budget de marketing
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .marketingBudget
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Budget de maintenance
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .maintenanceBudget
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coûts Recherche & Développement
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.rndCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Achats d'études
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.studies
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Frais du personnel
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.staffCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges.workerCosts
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des ouvriers
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.workerCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges.employeeCosts
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des employés
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .employeeCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges
                        .productManagerCosts
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des chefs de production
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .productManagerCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges
                        .serviceManagerCosts
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des managers
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .serviceManagerCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges.salesmanCosts
                    ) > 0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des commerciaux
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .salesmanCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-if="
                    parseFloat(
                      result.resultAccount.exploitation.charges.commissions
                    ) +
                      parseFloat(
                        result.resultAccount.exploitation.charges.salesmanCosts
                      ) >
                    0
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Commissions
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.commissions
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Coût des formations
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .trainingCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Budget social
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.socialBudget
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Frais d'embauches et de licenciements
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .hiringFiringCosts
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Dotations aux amortissements
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges.amortisation
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                  >
                    Dotations aux amortissements corporelles
                  </td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exploitation.charges
                            .corporealAmortisation
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres charges d'exploitations
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherExplotationChargesTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="exploitationCharge in result.resultAccount.exploitation
                    .charges.otherCharges"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="exploitationCharge.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(exploitationCharge.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat financier
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.financial.result)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Produits financiers
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.products.total
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Pénalités contractuelles perçues
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.products
                            .marketplacePenalityReceived
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Intérêts de placements de trésorerie
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.products
                            .treasuryPlacements
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres produits financiers
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherFinancialProductsTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="financialProduct in result.resultAccount.financial
                    .products.otherProducts"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="financialProduct.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(financialProduct.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Charges financières
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.financial.charges.total)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Pénalités contractuelles versées
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.charges
                            .marketplacePenalityPaid
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Intérêts des emprunts
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.charges.loansIntrests
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Pénalités de remboursement anticipé
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.charges.loansPenality
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Agios Découvert bancaire
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.charges
                            .overdraftInterests
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Frais d'escompte
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.financial.charges
                            .discountInterests
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres charges financières
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherFinancialChargesTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="financialCharge in result.resultAccount.financial
                    .charges.otherCharges"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="financialCharge.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(financialCharge.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat exceptionnel
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.exceptional.result)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Produits exceptionnels
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exceptional.products.total
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Cessions de machines
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exceptional.products
                            .machineSalesValue
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres produits exceptionnels
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherExceptionalProductsTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="exceptionalProduct in result.resultAccount.exceptional
                    .products.otherProducts"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="exceptionalProduct.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(exceptionalProduct.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="4"
                  >
                    Charges exceptionnelles
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exceptional.charges.total
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Valeur brute immobilisations corporelles vendues
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.exceptional.charges
                            .CorporealImmoSalesValue
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <td class="pl-5 text-left text-h5" colspan="3">
                    Autres charges financières
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(otherExceptionalChargesTotal)
                      )
                    "
                  ></td>
                </tr>
                <tr
                  v-for="exceptionalCharge in result.resultAccount.exceptional
                    .charges.otherCharges"
                  v-if="
                    session.displayParameters.otherDetails ||
                    profile.role != 'User'
                  "
                  :class="
                    !session.displayParameters.otherDetails &&
                    profile.role != 'User'
                      ? 'warning--text'
                      : ''
                  "
                >
                  <th></th>
                  <th></th>
                  <th></th>
                  <td
                    class="pl-5 text-left text-overline secondary--text"
                    colspan="2"
                    v-text="exceptionalCharge.description"
                  ></td>
                  <td
                    class="pr-5 text-right text-overline secondary--text"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(exceptionalCharge.value)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <th colspan="6"></th>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat avant impôts
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.totalBeforeTax)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5" colspan="5">
                    Variation de crédits d'impôts
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.resultAccount.newTaxCreditAmount -
                            result.resultAccount.TaxCreditAmount
                        )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat imposable
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.totalBeforeTax) -
                          Math.abs(
                            parseFloat(
                              result.resultAccount.newTaxCreditAmount -
                                result.resultAccount.TaxCreditAmount
                            ) < 0
                              ? parseFloat(
                                  result.resultAccount.newTaxCreditAmount -
                                    result.resultAccount.TaxCreditAmount
                                )
                              : 0
                          )
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="pl-5 text-left text-h5" colspan="5">
                    Impôts sur les bénéfices
                  </td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.taxAfterCredit)
                      )
                    "
                  ></td>
                </tr>
                <tr>
                  <td
                    class="pl-5 text-left text-h5 font-weight-bold"
                    colspan="5"
                  >
                    Résultat net
                  </td>
                  <td
                    class="pr-5 text-right text-h5 font-weight-bold"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(result.resultAccount.netTotal)
                      )
                    "
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "ResultAccount",
  props: ["profile", "user", "session", "result"],
  setup() {
    return {
      dataFormater,
    };
  },
  computed: {
    otherExplotationProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.exploitation &&
        this.result.resultAccount.exploitation.products &&
        this.result.resultAccount.exploitation.products.otherProducts
      ) {
        this.result.resultAccount.exploitation.products.otherProducts.forEach(
          (exploitationProduct) => {
            total += parseFloat(exploitationProduct.value);
          }
        );
      }
      return total;
    },
    otherExplotationChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.exploitation &&
        this.result.resultAccount.exploitation.charges &&
        this.result.resultAccount.exploitation.charges.otherCharges
      ) {
        this.result.resultAccount.exploitation.charges.otherCharges.forEach(
          (exploitationCharge) => {
            total += parseFloat(exploitationCharge.value);
          }
        );
      }
      return total;
    },
    otherFinancialProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.financial &&
        this.result.resultAccount.financial.products &&
        this.result.resultAccount.financial.products.otherProducts
      ) {
        this.result.resultAccount.financial.products.otherProducts.forEach(
          (financialProduct) => {
            total += parseFloat(financialProduct.value);
          }
        );
      }
      return total;
    },
    otherFinancialChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.financial &&
        this.result.resultAccount.financial.charges &&
        this.result.resultAccount.financial.charges.otherCharges
      ) {
        this.result.resultAccount.financial.charges.otherCharges.forEach(
          (financialCharge) => {
            total += parseFloat(financialCharge.value);
          }
        );
      }
      return total;
    },
    otherExceptionalProductsTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.exceptional &&
        this.result.resultAccount.exceptional.products &&
        this.result.resultAccount.exceptional.products.otherProducts
      ) {
        this.result.resultAccount.exceptional.products.otherProducts.forEach(
          (exceptionalProduct) => {
            total += parseFloat(exceptionalProduct.value);
          }
        );
      }
      return total;
    },
    otherExceptionalChargesTotal() {
      let total = 0;
      if (
        this.result &&
        this.result.resultAccount &&
        this.result.resultAccount.exceptional &&
        this.result.resultAccount.exceptional.charges &&
        this.result.resultAccount.exceptional.charges.otherCharges
      ) {
        this.result.resultAccount.exceptional.charges.otherCharges.forEach(
          (exceptionalCharge) => {
            total += parseFloat(exceptionalCharge.value);
          }
        );
      }
      return total;
    },
  },
};
</script>
