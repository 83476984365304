<template>
  <span>
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span>
          <v-simple-table
            v-if="
              result &&
              result.productionTable &&
              productsList &&
              productsList.length > 0
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="pl-5 text-center text-h5 font-weight-bold primary--text"
                    colspan="6"
                  >
                    Produit(s)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Produit(s)
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Production prévue
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Production effective
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Volume du stock initial
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Volume du stock final
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Valeur du stock final
                  </td>
                </tr>
                <tr
                  v-for="product of productsList"
                  v-if="
                    result.productionTable.products[product.uuid] != undefined
                  "
                >
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.products[product.uuid].product.name
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.products[product.uuid]
                        .desiredProduction
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.products[product.uuid]
                        .actualProduction
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.products[product.uuid]
                        .initialStockAmount
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.products[product.uuid]
                        .finalStockAmount
                    "
                  ></td>
                  <td
                    class="pr-5 text-right text-h5"
                    v-text="
                      dataFormater.formatMoney(
                        parseFloat(
                          result.productionTable.products[product.uuid]
                            .finalStockValue
                        )
                      )
                    "
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="
              result &&
              result.productionTable &&
              servicesList &&
              servicesList.length > 0
            "
            class="mt-12"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="pl-5 text-center text-h5 font-weight-bold primary--text"
                    colspan="6"
                  >
                    Service(s)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Service
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Production max prevue
                  </td>
                  <td class="pl-5 text-left text-h5 font-weight-bold">
                    Production effective
                  </td>
                </tr>
                <tr
                  v-for="service of servicesList"
                  v-if="
                    result.productionTable.services[service.uuid] != undefined
                  "
                >
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.services[service.uuid].service.name
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.services[service.uuid]
                        .desiredProduction
                    "
                  ></td>
                  <td
                    class="pl-5 text-left text-h5"
                    v-text="
                      result.productionTable.services[service.uuid]
                        .actualProduction
                    "
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "ProductionTable",
  props: ["profile", "user", "session", "result"],
  setup() {
    return {
      dataFormater,
    };
  },
  computed: {
    productsList() {
      let products = Object.values(this.session.productsConfig);
      products.sort((a, b) => (a.name > b.name ? 1 : -1));
      return products;
    },
    servicesList() {
      let services = Object.values(this.session.servicesConfig);
      services.sort((a, b) => (a.name > b.name ? 1 : -1));
      return services;
    },
  },
};
</script>
