var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',[(_vm.result && _vm.result.resultAccount)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat d'exploitation ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.exploitation.result)
                    )
                  )}})]),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Produits d'exploitation ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products.total
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Chiffre d'Affaires ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products.turnover
                      )
                    )
                  )}})]),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.products
                      .productsSalesValue
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Ventes de produits finis "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                        'Chiffre d\'affaires correspondant aux ventes de produits finis toutes zones confondues  (provenant de la production comme du marketplace)'
                      )}})])],1),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products
                          .productsSalesValue
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.products
                      .servicesSalesValue
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Ventes de services ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products
                          .servicesSalesValue
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.products
                      .marketplaceSalesReceived
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Ventes de marchandises "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                        'Chiffre d\'affaires correspondant aux ventes de marchandises effectuées sur le marketplace'
                      )}})])],1),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products
                          .marketplaceSalesReceived
                      )
                    )
                  )}})]):_vm._e(),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Variation stocks de produits finis ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products
                          .stockVariation
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Subventions d'exploitation "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                        'Valeur des subventions obtenues cette période pour vos différents challenges '
                      )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.products.subventions
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres produits d'exploitations ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherExplotationProductsTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount
                  .exploitation.products.otherProducts),function(exploitationProduct){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(exploitationProduct.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(exploitationProduct.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Charges d'exploitation ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.total
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Achats fournisseurs ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.rawMaterials
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Matières premières et consommables ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .materialCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Budget RSE et qualité ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .qualityBudget
                      )
                    )
                  )}})]),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges
                      .marketplaceSalesPaid
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Achats de marchandises "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                        'Valeur des achats de produits finis provenant du marketplace'
                      )}})])],1),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .marketplaceSalesPaid
                      )
                    )
                  )}})]):_vm._e(),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Coûts de services externes ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .externalCharges
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Frais de structure ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .structuralCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Frais de structures spécifiques machines ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .machineStructuralCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Frais de stockage ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.storageCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Frais de distribution ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .distributionCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Budget de communication ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .communicationBudget
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Budget de marketing ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .marketingBudget
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Budget de maintenance ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .maintenanceBudget
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coûts Recherche & Développement ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.rndCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Achats d'études ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.studies
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Frais du personnel ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.staffCosts
                      )
                    )
                  )}})]),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges.workerCosts
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des ouvriers ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.workerCosts
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges.employeeCosts
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des employés ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .employeeCosts
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges
                      .productManagerCosts
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des chefs de production ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .productManagerCosts
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges
                      .serviceManagerCosts
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des managers ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .serviceManagerCosts
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges.salesmanCosts
                  ) > 0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des commerciaux ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .salesmanCosts
                      )
                    )
                  )}})]):_vm._e(),(
                  parseFloat(
                    _vm.result.resultAccount.exploitation.charges.commissions
                  ) +
                    parseFloat(
                      _vm.result.resultAccount.exploitation.charges.salesmanCosts
                    ) >
                  0
                )?_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Commissions ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.commissions
                      )
                    )
                  )}})]):_vm._e(),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Coût des formations ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .trainingCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Budget social ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.socialBudget
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Frais d'embauches et de licenciements ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .hiringFiringCosts
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Dotations aux amortissements ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges.amortisation
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"}},[_vm._v(" Dotations aux amortissements corporelles ")]),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exploitation.charges
                          .corporealAmortisation
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres charges d'exploitations ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherExplotationChargesTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount.exploitation
                  .charges.otherCharges),function(exploitationCharge){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(exploitationCharge.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(exploitationCharge.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat financier ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.financial.result)
                    )
                  )}})]),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Produits financiers ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.products.total
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Pénalités contractuelles perçues ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.products
                          .marketplacePenalityReceived
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Intérêts de placements de trésorerie ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.products
                          .treasuryPlacements
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres produits financiers ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherFinancialProductsTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount.financial
                  .products.otherProducts),function(financialProduct){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(financialProduct.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(financialProduct.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Charges financières ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.financial.charges.total)
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Pénalités contractuelles versées ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.charges
                          .marketplacePenalityPaid
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Intérêts des emprunts ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.charges.loansIntrests
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Pénalités de remboursement anticipé ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.charges.loansPenality
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Agios Découvert bancaire ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.charges
                          .overdraftInterests
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Frais d'escompte ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.financial.charges
                          .discountInterests
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres charges financières ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherFinancialChargesTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount.financial
                  .charges.otherCharges),function(financialCharge){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(financialCharge.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(financialCharge.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat exceptionnel ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.exceptional.result)
                    )
                  )}})]),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Produits exceptionnels ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exceptional.products.total
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Cessions de machines ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exceptional.products
                          .machineSalesValue
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres produits exceptionnels ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherExceptionalProductsTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount.exceptional
                  .products.otherProducts),function(exceptionalProduct){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(exceptionalProduct.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(exceptionalProduct.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" Charges exceptionnelles ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exceptional.charges.total
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Valeur brute immobilisations corporelles vendues ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.exceptional.charges
                          .CorporealImmoSalesValue
                      )
                    )
                  )}})]),_c('tr',[_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"3"}},[_vm._v(" Autres charges financières ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.otherExceptionalChargesTotal)
                    )
                  )}})]),_vm._l((_vm.result.resultAccount.exceptional
                  .charges.otherCharges),function(exceptionalCharge){return (
                  _vm.session.displayParameters.otherDetails ||
                  _vm.profile.role != 'User'
                )?_c('tr',{class:!_vm.session.displayParameters.otherDetails &&
                  _vm.profile.role != 'User'
                    ? 'warning--text'
                    : ''},[_c('th'),_c('th'),_c('th'),_c('td',{staticClass:"pl-5 text-left text-overline secondary--text",attrs:{"colspan":"2"},domProps:{"textContent":_vm._s(exceptionalCharge.description)}}),_c('td',{staticClass:"pr-5 text-right text-overline secondary--text",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(exceptionalCharge.value)
                    )
                  )}})]):_vm._e()}),_c('tr',[_c('th',{attrs:{"colspan":"6"}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat avant impôts ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.totalBeforeTax)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"5"}},[_vm._v(" Variation de crédits d'impôts ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(
                        _vm.result.resultAccount.newTaxCreditAmount -
                          _vm.result.resultAccount.TaxCreditAmount
                      )
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat imposable ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.totalBeforeTax) -
                        Math.abs(
                          parseFloat(
                            _vm.result.resultAccount.newTaxCreditAmount -
                              _vm.result.resultAccount.TaxCreditAmount
                          ) < 0
                            ? parseFloat(
                                _vm.result.resultAccount.newTaxCreditAmount -
                                  _vm.result.resultAccount.TaxCreditAmount
                              )
                            : 0
                        )
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5",attrs:{"colspan":"5"}},[_vm._v(" Impôts sur les bénéfices ")]),_c('td',{staticClass:"pr-5 text-right text-h5",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.taxAfterCredit)
                    )
                  )}})]),_c('tr',[_c('td',{staticClass:"pl-5 text-left text-h5 font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" Résultat net ")]),_c('td',{staticClass:"pr-5 text-right text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
                    _vm.dataFormater.formatMoney(
                      parseFloat(_vm.result.resultAccount.netTotal)
                    )
                  )}})])],2)]},proxy:true}],null,false,3486069041)}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }